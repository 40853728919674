import React from 'react';
import {
  Button,
  Headings,
  NavDivider,
  Navigation,
  Texts,
} from '../../../../../retro';
import { useHistory } from 'react-router-dom';
import BookIcon from '@atlaskit/icon/glyph/book';
import { useSelector } from 'react-redux';
import useConfigHook from "App/Hooks/useConfigHook";


const PERSONS = {
  "lakshay.jain@antrepriz.com":true,
  "mohamed.mansoor@flipkart.com":true,
  "sampathkumar.j@flipkart.com":true,
  "maqsood.ahmed@flipkart.com":true,
  "syed.shabuddin@flipkart.com":true,
  "aavishkar@antrepriz.com" : true,
  "aditya.vyawhare@antrepriz.com" : true,
  "govind.singh@antrepriz.com" : true,
  "j.sudheer@cleartrip.com":true,
  "srirama.m@cleartrip.com":true,
  "karthikm1.vc@flipkart.com":true,
  "velvizhia.vc@cleartrip.com":true,
  "maqsood.ahmed@cleartrip.com":true,
  "shivasankar.mahanta@cleartrip.com":true,
  "syed.shabuddin@cleartrip.com":true,
  'aditya.tiwari@antrepriz.com':true,
  "syed.nawaz1@cleartrip.com":true,
	"muzammilkhan.vc@cleartrip.com":true,
	"maqsood.ahmed@cleartrip.com":true,
	"shivasankar.mahanta@cleartrip.com":true,
	"madhu.sj@antrepriz.com":true, 
  "sank@yopmail.com":true, // harshal stage access
  "harshal.jaiswal@antrepriz.com": true, //harshal prod access,
  "sampathkumar.j@cleartrip.com":true,
  "karthikm1.vc@flipkart.com":true,
  "velvizhia.vc@cleartrip.com":true
}

export default function Routes() {
  // Origin Code
  const { code } = useSelector((data) => ({
    code: data?.auth?.config?.origin?.code || '',
  }));
  const CONFIG = useConfigHook();

  const routes = [
    {
      title: 'Add Tags',
      desc: 'Utility to add tags',
      path: '/app/apps/meta/import/tags',
      btnLabel: 'Add Tags',
      codes: ['CLRTRP', 'CLTP-STG'],
    },
    {
      title: 'Cancel Agency Invoice / Switch to Reseller',
      desc: 'Cancell agency invoice and switch to reseller',
      path: '/app/apps/meta/import/cancelAgencySwitchToReseller',
      btnLabel: 'Add Tags',
      codes: ['CLRTRP', 'CLTP-STG'],
    },
  ]
  if(PERSONS[CONFIG.email]){
    routes.push({
      title: 'Regenerate Flight Cancellation Charges',
      desc: 'Regenerate Flight Cancellation Charges / Shift to Current Date',
      path: '/app/apps/meta/import/regenerate/flight/cancel',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Regenerate Ancillary Invoice Charges',
      desc: 'Regenerate Ancillary Invoice Charges / Shift to Current Date',
      path: '/app/apps/meta/import/regenerate/flight/ancillary',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Update ZERO BREAKUP GST Flight Charges',
      desc: 'Update 0 GST Flight Charges  / Zero gst charge cases',
      path: '/app/apps/meta/import/regenerate/flight/zeroGst',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Update Cancellation BREAKUP GST Flight Charges',
      desc: 'Update Cancellation GST Flight Charges / Regenerate with new cancellation charges',
      path: '/app/apps/meta/import/regenerate/flight/cancellationCharges',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Cancel Invoice and Shift To Agency',
      desc: 'Cancel Invoice and Shift To Agency / No gst is changed',
      path: '/app/apps/meta/import/cancel/invoice',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Shift Hotel Reseller Entity Controller',
      desc: 'Shift Hotel Reseller Entity Controller / Move invoice and service charge invoice of hotel to new gstin',
      path: '/app/apps/meta/import/hotel/invoice',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Shift Flight Reseller Entity Controller',
      desc: 'Shift Flight Reseller Entity Controller / Move invoice and service charge invoice of flight to new gstin',
      path: '/app/apps/meta/import/flight/invoice',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Shift Agency Entity Controller',
      desc: 'Shift Agency Entity Controller / Move  service charge invoice  to new gstin',
      path: '/app/apps/meta/import/agency',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Add Gstin and Generate Reseller Invoice',
      desc: 'This utility has to be used when gstin was not passed for hotel bookings',
      path: '/app/apps/meta/import/addGstin',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Change Agency Date Controller',
      desc: 'This utility has to be used when you want to change data of agency invoice',
      path: '/app/apps/meta/import/agency/date',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    routes.push({
      title: 'Update breakup charges',
      desc: 'This utility has to be used when breakups updated',
      path: '/app/apps/meta/import/breakup',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
  }

  const FLIGHT_AGENCY = [
    
  ];
  const HOTEL_AGENCY = [
    
  ];
  const HOTEL_RESELLER = [
    
  ];
  const FLIGHT_RESELLER = [
    
  ];

  const VISA_AGENCY = [

  ];

  const TRAIN_AGENCY = [

  ];

  const BUS_AGENCY = [

  ];

  
  if(PERSONS[CONFIG.email]){
    FLIGHT_AGENCY.push({
      title: 'Update Airline Breakups In Invoice',
      desc: 'Update airline breakups in invoices for agency invoices',
      path: '/app/apps/meta/utilities/flight/breakup',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    FLIGHT_AGENCY.push({
      title: 'Shift Agency Entity Controller',
      desc: 'Update gstin and shift to different entity controller',
      path: '/app/apps/meta/utilities/flight/agencySwitch',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    FLIGHT_RESELLER.push({
      title: 'Update Cancellation Charges In Invoice',
      desc: 'Update Cancellation Charges for reseller invoices',
      path: '/app/apps/meta/utilities/flight/reseller/cancelInvoice',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    FLIGHT_RESELLER.push({
      title: 'Update GST Slab Charges In Invoice',
      desc: 'Update Gst Charges for reseller invoices',
      path: '/app/apps/meta/utilities/flight/reseller/fixGst',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    HOTEL_AGENCY.push({
      title: 'Add Gstin And Switch to Reseller',
      desc: 'Move bookings without gstin to reseller by adding gst',
      path: '/app/apps/meta/utilities/hotel/agency/gst',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    HOTEL_AGENCY.push({
      title: 'Update Gstin And Move to Reseller',
      desc: 'Move bookings with gstin to different gstin and switch to reseller by adding correct gst',
      path: '/app/apps/meta/utilities/hotel/agency/gst/update',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    // move city
    HOTEL_AGENCY.push({
      title: 'Move to Different city',
      desc: 'Move bookings from one city to another like cleartrip pvt to cleartrip packages.',
      path: '/app/apps/meta/utilities/movetodifferentcity',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    HOTEL_RESELLER.push({
      title: 'Move to Agency',
      desc: 'Move booking to agency from reseller for cases like reseller invoice generated for international',
      path: '/app/apps/meta/utilities/hotel/reseller/agency',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    HOTEL_RESELLER.push({
      title: 'Cancel Reseller',
      desc: 'Move booking to agency from reseller for cases like where invoice not generated',
      path: '/app/apps/meta/utilities/hotel/reseller/cancel',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    HOTEL_RESELLER.push({
      title: 'Move to Different Entity',
      desc: 'Move bookings from one entity to another like cleartrip pvt to cleartrip packages',
      path: '/app/apps/meta/utilities/hotel/reseller/entity',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    // updatecityandmovetoreseller
    HOTEL_RESELLER.push({
      title: 'Update City and Move to Reseller',
      desc: 'Move bookings with gstin to different city and switch to reseller by adding correct gst.',
      path: '/app/apps/meta/utilities/updatecityandmovetoreseller',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    VISA_AGENCY.push({
      title: 'Shift Agency Entity Controller',
      desc: 'Update gstin and shift to different entity controller.',
      path: '/app/apps/meta/utilities/visaagency',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    TRAIN_AGENCY.push({
      title: 'Shift Agency Entity Controller',
      desc: 'Update gstin and shift to different entity controller.',
      path: '/app/apps/meta/utilities/trainagency',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
    BUS_AGENCY.push({
      title: 'Shift Agency Entity Controller',
      desc: 'Update gstin and shift to different entity controller.',
      path: '/app/apps/meta/utilities/busagency',
      btnLabel: 'REGENERATE',
      codes: ['CLRTRP', 'CLTP-STG'],
    })
  }

  return (
    <div>
      <Navigation
        backUrl={''}
        title="All Utilities"
        description="All utilities which is needed to correct invoices."
      />
      <NavDivider />
      <div className="card-container mt2">
          {routes.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>

      <div className='mt4'>
        <h3>
          When Invoice = Flights Agency
        </h3>
      </div>
      <div className="card-container mt2">
          {FLIGHT_AGENCY.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
      <div className='mt4'>
        <h3>
          When Invoice = Flights Reseller
        </h3>
      </div>
      <div className="card-container mt2">
          {FLIGHT_RESELLER.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
        <div className='mt4'>
        <h3>
          When Invoice = Hotel Reseller
        </h3>
      </div>
      <div className="card-container mt2">
          {HOTEL_RESELLER.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
        <div className='mt4'>
        <h3>
          When Invoice = Hotel Agency
        </h3>
      </div>
      <div className="card-container mt2">
          {HOTEL_AGENCY.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
        <div className='mt4'>
        <h3>
          When Invoice = Visa Agency
        </h3>
      </div>
      <div className="card-container mt2">
          {VISA_AGENCY.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
        <div className='mt4'>
        <h3>
          When Invoice = Train Agency
        </h3>
      </div>
      <div className="card-container mt2">
          {TRAIN_AGENCY.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
        <div className='mt4'>
        <h3>
          When Invoice = Bus Agency
        </h3>
      </div>
      <div className="card-container mt2">
          {BUS_AGENCY.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
    </div>
  );
}

export const ReportRouteCard = ({ reportRoute }) => {
  const HISTORY = useHistory();
  return (
    <div className="retro-card">
      <div className="relative ">
        <BookIcon size="xlarge" />
        <Headings.Regular margin="mt4">{reportRoute.title}</Headings.Regular>
        <Texts.Small style={{ maxLines: 2 }} className="">
          {reportRoute.desc}
        </Texts.Small>
        <Button
          onClick={() => {
            HISTORY.push(`${reportRoute.path}`);
          }}
          className="mt3 btn-max btn-primary"
        >
          {reportRoute.btnLabel}
        </Button>
      </div>
    </div>
  );
};