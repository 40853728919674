import React, { useEffect, useRef, useState } from "react";
import {
  Attachment,
  Button,
  Input,
  KeyValue,
  Loader,
  Modal,
  SelectInput,
  Seperator,
  SidePane,
  TOAST as Alert,
  TOAST,
  SearchInput,
  StatelessTab
} from "../../../../../retro";
import { HOTELCATEGORY, HOTELCHAINS, hotelTaxesBreakup, MEALPLAN } from "./Queued/Utils";
import { get, post } from "App/Network/Axios";
import Hotel from "./Hotel";
import SectionMessage from "@atlaskit/section-message";
import Moment from "moment";
import PaymentDocument from "./PaymentDocument";
import moment from "moment";

export default function ViewRequest({ id, onClose }) {
  const [data, setData] = useState({
    status: "LOADING",
  });
  const [txn, setTxn] = useState(undefined);
  const VOUCHER = useRef();
  const [amount, setAmount] = useState(0);
33
  const [purchaseAmount,setPurchaseAmount] = useState(0);
  const [gstSlab, setGstSlab] = useState(0);
  const [purchaseGstSlab,setPurchaseGstSlab] = useState(0);
  const [assignToOtherModal, setAssignToOtherModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [policyModal,setPolicyModal] = useState(false)
  const [amenitiesModal,setAmenitiesModal] = useState(false);
  const [DRemarks,setDRemarks] = useState(undefined);
  const HOTELID = useRef();
  const ADDRESS = useRef();
  const TYPE = useRef();
  const CITY = useRef();
  const [decline, setDecline] = useState(false);
  const ROOMNAME = useRef();
  let CHAIN = useRef();
  const ROOMINCULSIONS = useRef();
  const CANCELLATION = useRef();
  const PURCHASEPRICE = useRef();
  const SELLPRICEPERNIGHT = useRef();
  const PURCHASEPRICEPERNIGHT = useRef();
  const REMARKS = useRef();
  const DREMARKS = useRef();
  const [selectedAdmin, setSelectedAdmin] = useState();
  const CATEGORY = useRef();
  const NEWHOTEL = useRef();
  const OTHERREMARKS = useRef();
  const [toInvoiceQueueModal, setToInvoiceQueueModal] = useState(false);
  const [oldInvoices, setOldInvoices]=useState([]);
  const [tab, setTab]=useState('Details');
  const [hotel,setHotel] = useState();
  const [addHotel,setAddHotel] = useState(false);

  console.log("data", data);

  const load = (id) => {
    get(`/meta/hotel/queue/${id}/details`, (e, r) => {
      if (r) {
        setData(r);
        setOldInvoices(r.paymentDocument?.oldInvoices)
        if (r.transId) {
          loadTxn(r.transId);
        }
      } else {
        Alert.handleError(e);
      }
    });
  };
  const loadTxn = (id) => {
    get(`/meta/${id}/details`, (e, r) => {
      if (r) {
        setTxn(r);
      } else {
        TOAST.handleError(e);
      }
    });
  };


  let totalNights = Moment(data?.stayDetails?.checkOut, "DD-MM-YYYY").diff(
    Moment(data?.stayDetails?.checkIn, "DD-MM-YYYY"),
    "days"
  );

  // const {basePrice, taxes }=hotelTaxesBreakup(data?.stayDetails?.totalPrice?.toFixed(2) || 0)
  const {basePrice, taxes}=hotelTaxesBreakup(data?.stayDetails?.totalPrice || 0)



  useEffect(() => {
    load(id);
  }, [id]);
  useEffect(()=>{
    setHotel(data.hotel);
  },[data])
  if (data.status === "LOADING") {
    return (
      <SidePane
        title='Hotel Queue Request'
        description='Details of this hotel request queue appears below.'
        onClose={onClose}
      >
        <Loader />
      </SidePane>
    );
  }


  return (
    <SidePane
    tabs={
    <StatelessTab onClick={setTab} active={tab} tabs={['Details', 'Audit logs']}/>
    }
      preview={
        <div className='pd4' style={{ width: 475 }}>
          <h3 className='fw-bold'>Queue Details</h3>
          <div className='border pd4 mt4'>
            <h3 className='fw-bold'>Hotel Queue Request</h3>
            <p className='mb2'>Details of this hotel request appears below.</p>
            <div className='flex mt4 horizontally center-vertically'>
              <KeyValue title='Status' value={data.status} />
              <KeyValue title='Request Id' value={data.requestId} />
            </div>
            <div className='flex mt2 horizontally center-vertically'>
              <KeyValue title='Client Name' value={data.client.clientName} />
              <KeyValue title='Model Type' value={data?.reseller ? 'Reseller' : 'Agency'} />

              {data.paymentDocument?.attrs?.cancellationRemarks &&  
             <KeyValue title='Regenerated Remarks' value={data.paymentDocument?.attrs?.cancellationRemarks} />}    
            </div>
            <Seperator margin={2} />
            <p className='mb1'>Gst Details:</p>
            <h4>{data.gstDetails?.gstin}</h4>
            <p className='text-small'>{data.gstDetails?.name}</p>
            <p className='text-small'>{data.gstDetails?.email}</p>
            <Seperator margin={2} />
            <div>
              <p className='text fw-bold'>{data.hotel?.name}</p>
              <p className='text-small'>{data.hotel?.address || "-"}</p>
              <p className='text-small'>{data.hotel?.city || "-"}</p>
              <div className="flex horizontally mt2 space-bw">
                <p
                          className="text-small fw-bold text-underline"
                          style={{ color: 'var(--theme-primary-color)' }}
                          onClick={() => setAmenitiesModal(data?.room?.roomAmenities)}
                        >
                          Room Amenities
                </p>
                <p
                        className="text-small  fw-bold text-underline"
                        style={{ color: 'var(--theme-primary-color)' }}
                        onClick={() => setPolicyModal(data?.room?.cancellationPolicies)}
                      >
                        View Cancellation Policy
                </p>
              </div>
            </div>
            <Seperator margin={2} />
          <div className="flex  mt2 horizontally center-vertically">
            <KeyValue title='Notes' value={data.notes || data.remarks} />
           
            <KeyValue title='SRP Price' value={`${data.amount>  0? `Rs ${data?.amount}`:'No Srp shown'} `} />
            
             </div>

            <div className='flex mt2 horizontally center-vertically'>
              <KeyValue title='Check In' value={data.stayDetails.checkIn} />
              <KeyValue title='Check Out' value={data.stayDetails.checkOut} />
              <KeyValue
                title='Stay Details'
                value={`${data.stayDetails.numberOfRooms || 1} rooms * ${
                  Moment(data.stayDetails.checkOut, "DD-MM-YYYY").diff(
                    Moment(data.stayDetails.checkIn, "DD-MM-YYYY"),
                    "days"
                  ) || 1
                } nights`}
              />
            </div>
            <div className='flex mt2 horizontally '>
              <KeyValue title='Price Per Night(GST included)' value={data?.stayDetails?.totalPrice?.toFixed(2)/totalNights || "-"} />
              {/* <KeyValue title='Total Price(GST included)' value={data?.stayDetails?.totalPrice?.toFixed(2) || "-"} /> */}
              <KeyValue title='Room Type' value={data?.room?.roomType || "-"} />
              
            </div>
            <div className='flex mt2 horizontally '>
              <KeyValue title='Base Price' value={`${basePrice>  0? `Rs ${basePrice}`:'-'} `}/>
              <KeyValue title='Taxes' value={`${taxes>  0? `Rs ${taxes}`:'-'} `}/>
              <KeyValue title='Total Price(GST included)' value={data?.stayDetails?.totalPrice?.toFixed(2) || "-"} />
              {/* <KeyValue title='Room Type' value={data?.room?.roomType || "-"} /> */}
              
            </div>
            <div>
              <p className='fw-bold'>Travellers:</p>
              {data.guests.map((item, index) => {
                return (
                  <>
                  <div key={item.email} className='mt2 flex horizontally'>
                    <p className='fw-bold'>{index + 1})</p>
                    <div className='ml1 flex-2'>
                      <p>{item.name}</p>
                      <p className='text-small'><span className='fw-bold'>Email:</span> {item.email}</p>
                      <p className='text-small'><span className='fw-bold'>Mobile:</span> {item.mobile}</p>
                      <p className='text-small'><span className='fw-bold'>Grade:</span> {item?.grade}</p>
                      <p className='text-small'> <span className='fw-bold'>Programme:</span> {item?.programme}</p>
                    </div>
                    <div className='flex-1'>{item.gender}</div>
                  </div>
                  <div className='flex horizontally ml2'>
								<div className='flex-1'>
									<p className='text-small'>
									<span className='fw-bold'>Office:</span>  {item.office}
									</p>
									<p className='text-small'>
									<span className='fw-bold'>Department:</span> {item.department}
									</p>
									</div>
									<div className='flex-1'>
									<p className='text-small '>
									<span className='fw-bold'>Team:</span> {item.team}
									</p>
									<p className='text-small'>
									<span className='fw-bold'>Policy:</span> {item.policy}
									</p>
									</div>
								</div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      }
      title='Hotel Request'
      description='Details of this hotel request queue appears below.'
      onClose={onClose}
    >
      {tab==='Details' && 
      <div>
        {data.status === "FAILED" || data.status === "DECLINED" ? (
          <div className='mt4 border pd4'>
            <h3 className='fw-bold'>This booking has been {data.status}</h3>
            <p>{data.failedReason}</p>
          </div>
        ) : undefined}
        {data.status === "COMPLETED" ||
        data.status === "REFUNDING" ||
        data.status === "REFUNDED" ? (
          <>
            {data.paymentDocument ? (
              <div className='mt4 border pd4'>
                <PaymentDocument
                  data={data.paymentDocument}
                  id={data.transId}
                />
              </div>
            ) : null}
            <div className='mt4 border pd4'>
              <h3 className='fw-bold'>{data.transId}</h3>
              <p>{data.requestId}</p>
              <div className='mt2'>
                {!txn ? (
                  "This booking is loading...."
                ) : (
                  <div>
                    <Hotel {...txn} id={data.transId} />
                    <div className='border mt4 pd4'>
                      <p className='fw-bold mb4'>Documents</p>
                      <div className='flex horizontally'>
                        {txn.invoice?.file ? (
                          <div className='mr2'>
                            <Attachment url={txn.invoice.file} />
                            <p>Voucher</p>
                          </div>
                        ) : undefined}
                        {txn.invoice?.invoice !== "SKIPPED" ? (
                          <div className='mr2'>
                            <Attachment url={txn.invoice.invoice} />
                            <p>Invoice</p>
                          </div>
                        ) : undefined}
                        {txn.invoice?.serviceInvoice !== "SKIPPED" ? (
                          <div className='mr2'>
                            <Attachment url={txn.invoice.serviceInvoice} />
                            <p>Service Invoice</p>
                          </div>
                        ) : undefined}
                        {txn.invoice?.creditNote !== "PENDING" ? (
                          <div className='mr2'>
                            <Attachment url={txn.invoice.creditNote} />
                            <p>Credit Note</p>
                          </div>
                        ) : undefined}
                        {txn.invoice?.cancellationCharges !== "PENDING" ? (
                          <div>
                            <Attachment url={txn.invoice.cancellationCharges} />
                            <p>Cancellation Charges</p>
                          </div>
                        ) : undefined}
                      </div>
                    </div>
                    {data.paymentDocument?.oldInvoices.length>1 && 
                    <div className='border mt4 pd4'>
                      <p className='fw-bold mb4'>Old Documents</p>
                      <div className="flex horizontally"> 
                      {
                        oldInvoices.slice(0, oldInvoices.length-1).map((file)=>{
                          return  <div className='mr2 '>
                                 <Attachment url={file} />
                        </div>
                        })
                      }
                    </div>
                    </div>    
                      }
                    { data.queueStatus === "COMPLETED" && 
                      <div className="flex flex-1 vertical reverse">
                        <Button onClick={()=>setToInvoiceQueueModal(true)}>
                          Move To Invoice Queue
                        </Button>
                      </div>
                    }
                    
                  </div>
                )}
              </div>
            </div>
          </>
        ) : undefined}
        {data.status === "QUEUED" && (
          <div className='mt4'>
            <div className='mb2'>
              <SectionMessage title={data.admin.adminName}>
                <p>
                  This ticket is currently handled by {data.admin.adminEmail}
                </p>
                <div className='pt2'>
                  <p
                    onClick={() => {
                      post(`/meta/hotel/queue/${id}/self`, {}, (e, r) => {
                        if (r) {
                          load(id);
                          TOAST.success("Ticket assigned to you.");
                        } else {
                          Alert.handleError(e);
                        }
                      });
                    }}
                    className='btn-link btn'
                  >
                    Assign To Yourself
                  </p>
				  <span> / </span>
				  <p
				  	onClick={() => setAssignToOtherModal(true)}
                    className='btn-link btn'
                  >
                    Assign To Other
                  </p>
                </div>
              </SectionMessage>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={VOUCHER}
                  type='text'
                  label='Confirmation Number'
                  placeholder='Confirmation Number'
                  className='mr1 flex-1'
                />
              </div>
              <SearchInput label="Search Hotels" className='mt2' api='meta/hotel/searchByName' onSelect={(item)=>{
									setHotel(item)
                  setAddHotel(false)
								}} placeholder='Search Hotel'
									renderResult={item => (
										<div>{item.name}</div>
									)}
                  selected={hotel?.name}
							/>
              <div className="mt2">
                 <p style={{cursor:"pointer",color:"blue"}} onClick={()=>{setAddHotel(!addHotel);setHotel(null)}}>+ Add Hotel</p>
              </div>
              {
                addHotel && <div className='flex horizontally center-vertically mt2'>
                    <Input
                      ref={NEWHOTEL}
                      type='text'
                      label='Add Hotel Name'
                      placeholder='Add Hotel Name'
                      className='mr1 flex-1'
                    />
                  </div>
              }
             
               <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={HOTELID}
                  defaultValue={hotel?.hotelId || null}
                  type='text'
                  label='Hotel ID'
                  placeholder='Hotel ID'
                  className='mr1 flex-1'
                  // disabled={true}
                />
              </div>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={ADDRESS}
                  defaultValue={hotel?.address || null}
                  type='text'
                  label='Hotel Address'
                  placeholder='Hotel Address'
                  className='mr1 flex-1'
                  // disabled={true}
                />
              </div>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  defaultValue={hotel?.city || ''}
                  ref={CITY}
                  type='text'
                  label='City'
                  placeholder='Enter City'
                  className='mr1 flex-1'
                  // disabled={true}
                />
              </div>
              <div className='flex horizontally center-vertically w-100 mt2'>
                <SelectInput
                  className='w-100'
                  ref={CATEGORY}
                  label='Hotel Category'
                  options={HOTELCATEGORY.map((value) => ({ value, label: value }))}
                />
              </div>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={ROOMNAME}
                  type='text'
                  label='Room Name'
                  placeholder='Room Name'
                  className='mr1 flex-1'
                />
              </div>
              <div className='flex horizontally center-vertically w-100 mt2'>
                <SelectInput
                  className='w-100'
                  ref={ROOMINCULSIONS}
                  label='Meal Plan'
                  options={MEALPLAN.map((value) => ({ value, label: value }))}
                />
              </div>
              <div className='flex horizontally center-vertically w-100 mt2'>
                <SelectInput
                  className='w-100'
                  ref={CHAIN}
                  label='Hotel Chain'
                  options={HOTELCHAINS.map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </div>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={CANCELLATION}
                  type='text'
                  label='Cancellation Policy'
                  placeholder='Entry Cancellation Policy'
                  className='mr1 flex-1'
                />
              </div>
              {/* <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={PURCHASEPRICE}
                  type='number'
                  label='PURCHASE PRICE'
                  placeholder='PURCHASE PRICE'
                  className='mr1 flex-1'
                />
              </div> */}
               <div className='flex horizontally center-vertically mt2'>
                <Input
                  onChange={(e) => {
                    try {
                      let value = PURCHASEPRICEPERNIGHT.current.value;
                      value = parseFloat(value).toFixed(3);
                      let nights = Moment(
                        data.stayDetails.checkOut,
                        "DD-MM-YYYY"
                      ).diff(
                        Moment(data.stayDetails.checkIn, "DD-MM-YYYY"),
                        "days"
                      );
                      if (nights < 1) nights = 1;
                      let totalPrice = nights * value;
                      let gstSlab =  value > 7500 ? 18 : 12;
                      if (data.stayDetails.numberOfRooms) {
                        totalPrice =
                          totalPrice * data?.stayDetails?.numberOfRooms || 1;
                      }
                      totalPrice = totalPrice + gstSlab * (totalPrice / 100);
                      setPurchaseGstSlab(gstSlab);
                      setPurchaseAmount(totalPrice?.toFixed(3) || 0);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  ref={PURCHASEPRICEPERNIGHT}
                  type='number'
                  label='Purchase Price Per Night Exc. of Gst'
                  placeholder='Purchase Price Per Night Exc. of Gst'
                  className='mr1 flex-1'
                />
              </div>
              <p className='mt2 primary-color'>
                {purchaseGstSlab > 0 && `Gst will be applied with ${purchaseGstSlab}% slab.`}
              </p>
              <div className='flex horizontally center-vertically mt2'>
                <KeyValue
                  title='Total Purchase Amount'
                  value={`INR ${parseFloat(purchaseAmount)?.toFixed(3)}`}
                />
              </div>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  onChange={(e) => {
                    try {
                      let value = SELLPRICEPERNIGHT.current.value;
                      value = parseFloat(value)?.toFixed(3)
                      let nights = Moment(
                        data.stayDetails.checkOut,
                        "DD-MM-YYYY"
                      ).diff(
                        Moment(data.stayDetails.checkIn, "DD-MM-YYYY"),
                        "days"
                      );
                      if (nights < 1) nights = 1;
                      let totalPrice = nights * value;
                      let gstSlab =  value > 7500 ? 18 : 12;
                      if (data.stayDetails.numberOfRooms) {
                        totalPrice =
                          totalPrice * data?.stayDetails?.numberOfRooms || 1;
                      }
                      totalPrice = totalPrice + gstSlab * (totalPrice / 100);
                      setGstSlab(gstSlab);
                      setAmount(totalPrice?.toFixed(3) || 0);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  ref={SELLPRICEPERNIGHT}
                  type='number'
                  label='Sell Price Per Night Exc. of Gst'
                  placeholder='Sell Price Per Night Exc. of Gst'
                  className='mr1 flex-1'
                />
              </div>
              <p className='mt2 primary-color'>
                {gstSlab > 0 && `Gst will be applied with ${gstSlab}% slab.`}
              </p>
              <div className='flex horizontally center-vertically mt2'>
                <KeyValue
                  title='Total Sell Amount'
                  value={`INR ${parseFloat(amount)?.toFixed(3)}`}
                />
              </div>
              <div className='flex horizontally center-vertically'>
                <SelectInput
                  options={[
                    { label: "CONTRACTED", value: "CONTRACTED" },
                    { label: "NON-CONTRACTED", value: "NON-CONTRACTED" },
                    { label: "NON-CONTRACTED-VTP", value: "NON-CONTRACTED-VTP" },
                  ]}
                  ref={TYPE}
                  type='number'
                  label='Type'
                  hidePlaceholder={true}
                  className='mr1 flex-1'
                />
              </div>
              <div className='flex horizontally center-vertically mt2'>
                <Input
                  ref={REMARKS}
                  type='text'
                  label='Remarks'
                  placeholder='Internal remarks'
                  className='flex-1'
                />
              </div>
              <div className='flex horizontally center-vertically mt4 '>
                <Button
                  onClick={() => {
                    setConfirmLoading(true);
                    let pricePerNight = Number(parseFloat(
                      SELLPRICEPERNIGHT.current.value
                    )?.toFixed(3));
                    let purchasePricePerNight = Number(parseFloat(
                      PURCHASEPRICEPERNIGHT.current.value
                    )?.toFixed(3));
                    let gst = pricePerNight < 7500 ? 12 : 18;
                    let purchaseGst = purchasePricePerNight <7500 ? 12 : 18;

                    // let purchasePriceExcGst = PURCHASEPRICE.current.value;
                    let mAmount = Number(parseFloat(amount)?.toFixed(3));
                    let pAmount = Number(parseFloat(purchaseAmount)?.toFixed(3));
                    let meal = ROOMINCULSIONS.current.value;
                    let city = CITY.current.value;
                    let hotelId = HOTELID.current.value;
                    let cancellation = CANCELLATION.current.value;
                    let roomName = ROOMNAME.current.value;
                    let name = addHotel?NEWHOTEL.current.value:hotel.name
                    let address = ADDRESS.current.value;
                    let voucher = VOUCHER.current.value;
                    let remarks = REMARKS.current.value;
                    let chain = CHAIN.current.value;
                    let category = CATEGORY.current.value;
                    if (!voucher) {
                      TOAST.error("Enter a valid confirmation number");
                      return;
                    }

                    if (!name) {
                      TOAST.error("Enter a valid hotel name");
                      return;
                    }

                    if (!address) {
                      TOAST.error("Enter a valid address");
                      return;
                    }

                    if (!city) {
                      TOAST.error("Enter a valid city");
                      return;
                    }

                    if (!roomName) {
                      TOAST.error("Enter a valid roomName");
                      return;
                    }

                    if (!cancellation) {
                      TOAST.error("Enter a valid cancellation");
                      return;
                    }

                    if (!meal) {
                      TOAST.error("Enter a valid meal");
                      return;
                    }
                    if(pAmount< 500){
                      TOAST.error("Enter valid purchase price");
                      return;
                    }
                    if (mAmount < 500) {
                      TOAST.error("Enter a valid amount");
                      return;
                    }

                    if (!pricePerNight || pricePerNight < 500) {
                      TOAST.error("Enter a valid sell price per night");
                      return;
                    }

                    if(!purchasePricePerNight || purchasePricePerNight < 500){
                      TOAST.error("Enter a valid purchase price per night");
                      return;
                    }

                    if (!category) {
                      TOAST.error("Enter a valid hotel category");
                      return;
                    }

                    let data = {
                      hotel: {
                        name,
                        address,
                        city,
                        category,
                        id:hotelId,
                      },
                      room: {
                        name: roomName,
                        meal: meal,
                        cancellation: cancellation,
                      },
                      amount: mAmount,
                      totalSellPrice: mAmount,
                      pricePerNight,
                      totalPrice: Number(amount),
                      purchasePricePerNight,
                      totalPurchasePrice: Number(pAmount),
                      purchasePriceGst: purchaseGst,
                      chain,
                      gst,
                      type: TYPE.current.value,
                      voucher,
                      remarks,
                    };
                    post(`/meta/hotel/queue/${id}/order`, data, (e, r) => {
                      setConfirmLoading(false);
                      if (r) {
                        load(id);
                      } else {
                        Alert.handleError(e);
                      }
                    });
                  }}
                  className='btn-black btn-primary'
                  loading={confirmLoading}
                  margin=''
                >
                  Confirm Booking
                </Button>
                <Button
                  onClick={() => {
                    setDecline(true);
                  }}
                  className='btn btn-danger'
                  margin='ml2'
                >
                  Decline Booking
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      }

      {tab ==='Audit logs' && 
             data?.logsDocuments && data?.logsDocuments?.length > 0 ? (
              data?.logsDocuments.map((log, index) => (
                <div key={log.time}>
                  <div className="flex align-stretch center-vertically horizontally mt2">
                    <div className="flex vertically" style={{ width: 30 }}>
                      <div
                        style={{
                          height: 8,
                          width: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          background: '#6367FA',
                        }}
                      />
                      {index !== data?.logsDocuments.length - 1 && (
                        <div
                          className="flex-1"
                          style={{ width: 4, borderRight: '1px dashed #dedede' }}
                        />
                      )}
                    </div>
                    <div className="flex-1 mr4">
                   {log.changesOwnerName && log.changesOwnerEmail &&  <p style={{ fontSize: 16 }}>
                   {log.changesOwnerName}-{log.changesOwnerEmail}
                      </p>}
                      <div style={{ marginTop: 8 }}>{log.message}</div>
                      {log.remarks && (
                        <div style={{ marginTop: 4, fontSize: 12 }}>
                          <span style={{ fontWeight: 500 }}>Remarks: </span>
                          {log?.remarks}
                        </div>
                      )}
                    </div>
                    <p  style={{ fontSize: 12 }}>
                      {moment(log.time).format('DD-MM-YYYY')}  {moment(log.time).format('HH:mm a')}
                    </p>
                  </div>
                  {index !== data?.logsDocuments.length - 1 && (
                    <div
                      style={{
                        height: 35,
                        width: 4,
                        borderRight: '1px dashed #dedede',
                      }}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="w-100 text-left mt2">No Logs</div>
            )
      }
      {decline && (
        <Modal
          onClose={() => {
            setDecline(false);
          }}
          title='Decline Remarks'
          description='Enter your decline remarks'
        >
          <SelectInput
            options={[
              { label: "Select Remarks", value: ""},
              { label: "Not Available", value: "Not Available" },
              { label: "Customer denied alternative", value: "Customer denied alternative" },
              { label: "TAT Breach", value: "TAT Breach" },
              { label: "Unable to reach the guest", value: "Unable to reach the guest" },
              { label: "Guest requested for cancellation", value: "Guest requested for cancellation" },
              { label: "Hotel not available, requested guest for self arraangement", value: "Hotel not available, requested guest for self arraangement" },
              { label: `Duplicate booking (${data.requestId})`, value: `Duplicate booking (${data?.requestId || "-"})` },
              { label: "Others", value:"Others" }
            ]}
            // ref={DREMARKS}
            onChange = {(val)=>setDRemarks(val)}
            label='Remarks'
            hidePlaceholder={true}
            className='mr1 flex-1'
          />

          {
            DRemarks === "Others" && 
            <Input
              ref={OTHERREMARKS}
              type='text'
              label='Enter other remarks'
              placeholder='Enter your remarks'
              className='flex-1 mt2'
            />
          }
            
          <Button
            className='mt4 btn btn-danger'
            onClick={() => {
              let remarks = DRemarks
              remarks = remarks==="Others"?OTHERREMARKS.current.value:remarks
              if (!remarks) {
                TOAST.error("Enter a valid remarks");
                return;
              }
              post(`/meta/hotel/queue/${id}/decline`, { remarks }, (e, r) => {
                if (r) {
                  load(id);
                  TOAST.success("Ticket declined successfully!");
                  setDRemarks(undefined);
                  setDecline(false);
                  onClose();
                } else {
                  Alert.handleError(e);
                }
              });
            }}
          >
            Cancel Booking
          </Button>
        </Modal>
      )}
	  {assignToOtherModal && (
		<Modal
			onClose={() => {
				setAssignToOtherModal(false);
				setSelectedAdmin(undefined);
			}}
			title="Assign To Other"
			description="Select an admin to assign"
			style={{
				height: 500
			}}
		>
			<SearchInput
				renderResult={item => {
					return (
						<p style={{ fontSize:14 }}>
							{item.name} ({item.email})
						</p>
					)
				}}
				resultKey="items"
				api="/admins"
				morph={false}
				label="Select Admin"
				onSelect={admin => {
					setSelectedAdmin(admin);
				}}
				placeholder="Select an admin"
			/>
			<Button
				onClick={() => {
					if (!selectedAdmin) {
						return TOAST.error('Select an admin to assign');
					}

					post(`/meta/hotel/queue/${id}/${selectedAdmin.id}/assign`, {}, (e, r) => {
						if (r) {
							load(id);
							TOAST.success(`Ticket assigned to ${selectedAdmin.name}`);

							setSelectedAdmin(undefined);
							setAssignToOtherModal(false);
						} else {
							Alert.handleError(e);
						}
					});
				}}
				className='btn-black btn-primary'
			>
				Assign
			</Button>
		</Modal>
	  )}
    {
      toInvoiceQueueModal &&
      <Modal
      onClose={() => {
        setToInvoiceQueueModal(false);
      }}
      title='Moving To Invoice Queue Remarks'
      description='Enter your remarks'
    >
      <Input
        label='Remarks'
        placeholder='Enter your remarks'
        ref={DREMARKS}
      />
      <Button
        className='mt4 btn btn-black'
        onClick={() => {
          let remarks = DREMARKS.current.value;
          if (!remarks) {
            TOAST.error("Enter a valid remarks");
            return;
          }
          post(`/meta/hotel/queue/${id}/status`, { remarks, status:'INVOICE' }, (e, r) => {
            if (r) {
              load(id);
              TOAST.success("Ticket moved to invoice queue successfully!");
              onClose();
            } else {
              TOAST.error(e);
            }
          });
        }}
      >
        Move To Invoice Queue  
      </Button>
    </Modal>
    }
     {policyModal && (
            <Modal
              title="Cancellation Policy"
              onClose={() => setPolicyModal(false)}
              description=""
            >
              <div className="text-small mt1">
                {policyModal?.map((policy) => (
                    <p>• {policy}</p>
                  ))}
              </div>
            </Modal>
          )}
          {amenitiesModal && (
            <Modal
              title="Room Amenities"
              onClose={() => setAmenitiesModal(false)}
              description=""
            >
              <div className="text-small mt1">
                {amenitiesModal?.map((amenity) => (
                    <p>• {amenity}</p>
                  ))}
              </div>
            </Modal>
          )}
    </SidePane>
  );
}
